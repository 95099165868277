<template>
  <v-container>
    <v-row>
      <v-col
        cols="6"
        class="text-left mt-2"
      >
        <v-icon
          large
          class="mb-4"
        >
          mdi-account-group-outline
        </v-icon>
        <span class="text-h2">Teams</span>
      </v-col>
      <v-col
        cols="6"
        class="text-right"
      >
        <v-btn
          v-if="$store.state.Permissions.TeamCreate"
          color="primary"
          fab
          large
          @click="createTeamDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="my-0 py-0"
      >
        <TeamsTreeView v-show="teams" :teams="teams" />
      </v-col>
    </v-row>

    <v-dialog
      v-model="createTeamDialog"
      width="600"
    >
      <v-card>
        <v-card-title
          class="white--text primary"
        >
          Create Team
        </v-card-title>
        <v-form
          ref="createTeamForm"
          v-model="valid"
          class="overflow-hidden"
        >
          <v-row class="pa-4">
            <v-col cols="12">
              <v-text-field
                v-model="name"
                outlined
                label="Name*"
                :rules="requiredRule"
                hide-details
              />
            </v-col>

            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                color="primary"
                @click="addNewTeam"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import TeamsTreeView from '@/components/teams/TreeView'
  import UserController from '@/services/controllers/User'

  export default {
    components: {
      TeamsTreeView,
    },
    
    data: () => ({
      createTeamDialog: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'friendlyName',
        }
      ],
      name: null,
      teams: [],
      valid: false,
      requiredRule: [(v) => !!v || 'Field is required'],
    }),

    async created () {
      await this.getTeams()
    },

    methods: {

      

      async getTeams () {
        let teamData = await UserController.getTeams()
        this.teams = teamData.data
        console.log("Teams: ", this.teams)
      },

      addNewTeam () {
        this.$refs.createTeamForm.validate()
        if (this.valid === true) {
          UserController.addTeam({
            name: this.name,
          })
            .then((res) => {
              this.name = null
              this.$root.$emit(
                'snackbarSuccess',
                'Team added successfully.',
              )
              this.teams.push(res.data)
            })
            .catch((err) => {
              this.$root.$emit('snackbarError', ''+err.response.data.message)
            })
            .finally(() => {
              this.createTeamDialog = false
            })
        }
      },

      genRandomIndex (length) {
        return Math.ceil(Math.random() * (length - 1))
      },
    },
  }
</script>
<style>

</style>
