<!-- dialogs/RemoveUserDialog.vue -->
<template>
    <v-dialog v-model="dialogModel" width="600">
      <v-card>
        <v-card-title class="white--text primary">Remove User</v-card-title>
        <div class="pa-4 text-center">
            <span>
          Are you sure you want to remove
          <b>{{ userName }}</b> from the <b>{{ teamName }}</b> team?
        </span>
        </div>
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn color="red" @click="$emit('confirm')">
            Confirm
          </v-btn>
          <v-btn color="primary" @click="$emit('cancel')">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: 'RemoveUserDialog',
    
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      user: {
        type: Object,
        default: null,
      },
      team: {
        type: Object,
        default: null,
      },
    },
  
    computed: {
      dialogModel: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
      userName() {
        if (!this.user?.contactCard) return ''
        return `${this.user.contactCard.firstName} ${this.user.contactCard.surname} - ${this.user.email}`
      },
      teamName() {
        return this.team?.name || ''
      },
    },
  }
  </script>
  